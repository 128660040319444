@import "../../breakpoints";

.catering-options-container {
  margin-top: 20px;
}

.catering-option {
  background-color: rgba(244, 16, 1, .7);
  color: #ffffff;
  display: inline-block;
  position: relative;
  margin: 0 0 20px 0;
  padding: 27px 21px;
  width: 100%;

  @include for-size(big-phone-up) {
    width: 380px;
  }

  @include for-size(tablet-portrait-up) {
    margin: 0 20px 20px 0;
    width: calc(50% - 20px);
    max-width: 380px;
  }

  span {
    display: block;
  }
  
  .catering-option-header {
    font-family: "Fjalla One";
    margin-bottom: 20px;
    font-size: 24px;
    margin: 0 0 20px;
  }

  .catering-option-item {
    margin: 0 0 3px;
    font-size: 20px;
    font-family: "Noto Serif";
    
    @include for-size(phone-only) {
      font-size: 18px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .catering-option-price {
    text-align: right;
    top: 4px;
    position: relative;
    font-size: 20px;
    font-family: "Fjalla One";
    margin: 0;
  }
}
