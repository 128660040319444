@import "../../breakpoints";

.footer {
  background-color: #333333;
  color: #ffffff;
  padding: 24px;
  justify-content: space-between;
  position: relative;
  
  @include for-size(phone-only) {
    align-items: center;
    flex-direction: column;
  }

  a {
    color: #ffffff;
    text-decoration: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  li {
    display: inline;
    padding: 0 10px;

    &:not(:first-child) {
      border-left: 1px solid #000;
      border-color: #ffffff;
    }
  }

  .footer-links {
    padding: 24px 10px;

    
    @include for-size(phone-only) {
      padding: 24px 0px;
    }
  }

  .footer-copyright {
    padding: 24px;
  }

  .icon-copyrights {
    position: absolute;
    visibility: hidden;
  }
}
