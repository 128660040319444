@import "../../breakpoints";

.menu-page {
  flex-direction: column;
}

.menu-header {
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  flex-direction: column;

  @include for-size(tablet-landscape-up) {
    margin-bottom: 30px;
  }

  h1 {
    margin: 0 auto;
  }
}

.menu-vary {
  width: 100%;
  padding: 20px 10px;
  border: 2px solid black;
  margin: 55px -10px 33px;
  text-align: center;
  color: #000000;
  font-weight: 700;

  @include for-size(tablet-landscape-up) {
    width: 85%;
    font-size: 20px;
    margin: 55px auto 33px;
  }
}

.menu-imgs {
  display: flex;
  flex-direction: column;
  padding: 20px;

  @include for-size(phone-only) {
    position: relative;
    right: 5px;
    margin-top: 7px;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.food-imgs {
  @include for-size(big-phone-up) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.food-img-container {
  position: relative;

  @include for-size(big-phone-up) {
    width: 50%;
  }

  span {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px;
    background-color: #ffffff;
    font-size: 17px;
  }
}
