@import "./breakpoints";

.box {
  display: flex;
  box-sizing: border-box;
  -webkit-box-align: center;
  min-width: 0px;
  min-height: 0px;
  flex-direction: row;
  outline: none;
  margin: 0px;
  // flex: 1 1 50%;
}

.full-page {
  min-height: 100vh !important;
  position: relative;
  align-items: unset;

  &.inverted {
    h1 {
      color: #000000;
    }
  }

  .full-page-background-img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

.full-page-layout {
  padding: 120px 60px 100px !important;
  max-width: 1100px !important;
  margin: 0 auto !important;
  flex-direction: column;

  @include for-size(phone-only) {
    padding: 130px 45px 50px !important;
  }

  @include for-size(tablet-portrait-up) {
    padding: 160px 80px 100px !important;
  }

  @include for-size(tablet-landscape-up) {
    padding: 160px 110px 100px !important;
  }

  @include for-size(desktop-up) {
    padding: 160px 180px 100px !important;
  }
}

p {
  color: #ffffff;
  font-family: "Carrois Gothic";
  max-width: none !important;
}

h1 {
  color: #ffffff;
  font-weight: 400 !important;
  margin: 0;
  font-size: 34px;

  @include for-size(big-phone-up) {
    font-size: 38px;
  }

  @include for-size(tablet-portrait-up) {
    font-size: 48px;
  }
}

.image {
  object-fit: cover;
  flex: 1 1 0%;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: #000000;
  opacity: .5;
  max-width: 100%;

  &.close {
    display: none;
  }
}

button {
  a {
    color: #000000;

    &:active {
      color: #000000;
    }
  }

  &.secondary {
    background: #000000;

    a {
      color: #ffffff;
    }
  }
}

.overflow-hidden {
  overflow: hidden;
}
