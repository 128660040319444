@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) { @content; }
  } @else if $size == big-phone-up {
    @media (min-width: 600px) { @content; }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 768px) { @content; }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 950px) { @content; }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) { @content; }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) { @content; }
  }
}

// Example usage
// .my-box {
//   padding: 10px;
  
//   @include for-size(desktop-up) {
//     padding: 20px;
//   }
// }
